import logo from "../assets/design/logo.svg";
import {useWindowSize} from "../hooks/useWindowSize";
import "./header.css";
import cx from "classnames";
import {Link} from "gatsby";
import {OutboundLink} from "gatsby-plugin-google-gtag";
import React, {useEffect, useRef, useState} from "react";

const StyledLink = ({className, children, ...props}) => {
    className = cx(className);
    return (
        <Link className={className} {...props}>
            {props.children}
        </Link>
    );
};

const HeaderLink = ({className, children, ...props}) => {
    className = cx(
        "w-full md:w-auto py-2 md:px-1 -mb-1 text-sm font-medium uppercase hover:text-green-500 border-transparent border-b-4 md:hover:border-green-600 relative",
        className
    );
    return (
        <div className="flex px-1">
            <Link
                className={`${className}`}
                {...props}
                activeClassName="md:border-green-600 hidden md:block cursor-default"
            >
                {children}
            </Link>
        </div>
    );
};

const HeaderDropdown = ({
                            className,
                            title,
                            children,
                            isExpanded,
                            ...props
                        }) => {
    className = cx(
        "w-full md:w-auto py-2  md:px-1 -mb-1 text-sm font-medium uppercase hover:text-green-500 border-transparent pl-1 cursor-pointer border-b-4 md:hover:border-green-600 relative",
        className
    );
    const [isOpen, setIsOpen] = React.useState(false);
    const [...items] = React.Children.toArray(children);
    const dropNode = useRef();

    const handleClick = (e) => {
        if (dropNode.current.contains(e.target)) {
            return;
        }
        setIsOpen(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    return (
        <div className="header-dropdown">
            <a
                className={`${className} hidden md:inline-block`}
                onClick={() => setIsOpen(!isOpen)}
            >
                {title}
            </a>
            <div
                ref={dropNode}
                className={`${
                    isExpanded
                        ? `perm-open md:hidden`
                        : `${isOpen ? `open shadow-md` : `hidden`}`
                }`}
            >
                {items}
            </div>
        </div>
    );
};

const HeaderDropdownItem = ({className, children, onClick, ...props}) => {
    className = cx(
        "header-dropdown-item",
        "w-full md:w-auto -ml-2 md:ml-0 md:px-1 text-sm font-medium uppercase text-green-700 hover:text-green-500 md:hover:border-green-600 relative",
        className
    );

    return (
        <Link
            className={className}
            {...props}
            activeClassName="md:border-green-600 hidden md:inline-block"
        >
            {children}
        </Link>
    );
};

const HeaderDropdownHref = ({
                                className,
                                href,
                                children,
                                onClick,
                                ...props
                            }) => {
    className = cx(
        "header-dropdown-item",
        "w-full md:w-auto -ml-2 md:ml-0 md:px-1 text-sm font-medium uppercase text-green-700 hover:text-green-500 md:hover:border-green-600 relative",
        className
    );

    return (
        <OutboundLink
            className={className}
            {...props}
            activeClassName="md:border-green-600"
            href={href}
        >
            {children}
        </OutboundLink>
    );
};

const Header = ({className, ...props}) => {
    let [isExpanded, toggleExpansion] = useState(false);
    const {width} = useWindowSize();
    const node = useRef();

    const handleClick = (e) => {
        if (node.current.contains(e.target)) {
            return;
        }
        toggleExpansion(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    useEffect(() => {
        if (width > 767) {
            toggleExpansion(false);
        }
    }, [width, toggleExpansion]);

    className = cx("w-full shadow-md z-50", className);
    return (
        <div ref={node} className={className} {...props}>
            <div
                className="z-40 flex flex-wrap flex-row items-start md:items-center justify-between px-2 sm:px-6 md:px-10 lg:px-24 text-green-700 fixed bg-white w-screen border-b border-gray-200">
                <div className="my-2">
                    <div className="w-full">
                        <Link to="/">
                            <img
                                className="header-logo"
                                alt="City of Oaks Cremation"
                                src={logo}
                            />
                        </Link>
                    </div>
                </div>

                <button
                    className="block relative float-right md:hidden border border-green-700 mt-4 px-3 py-2"
                    onClick={() => toggleExpansion(!isExpanded)}
                >
                    <svg
                        className="fill-current h-3 w-3"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <title>Menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                    </svg>
                </button>

                <div
                    className={`${
                        isExpanded ? `block` : `hidden`
                    } w-full md:w-auto flex md:flex flex-col md:flex-row justify-center md:justify-end items-stretch md:items-center py-1`}
                >
                    <HeaderLink to="/">Home</HeaderLink>
                    <HeaderDropdown isExpanded={isExpanded} title="About Us">
                        <HeaderDropdownItem to="/about/">About</HeaderDropdownItem>
                        <br/>
                        <HeaderDropdownItem to="/team/">Our Team</HeaderDropdownItem>
                        <br/>
                        <HeaderDropdownItem to="/testimonials/">
                            Testimonials
                        </HeaderDropdownItem>
                    </HeaderDropdown>

                    <HeaderDropdown isExpanded={isExpanded} title="Our Services">
                        <HeaderDropdownItem to="/services/cremation/">
                            Direct Cremation
                        </HeaderDropdownItem>
                        <br/>
                        <HeaderDropdownItem to="/services/cremation-with-solidified-remains/">
                            Cremation with Solidified Remains
                        </HeaderDropdownItem>
                        <br/>
                        <HeaderDropdownItem to="/services/cremation-with-visitation/">
                            Cremation & Visitation
                        </HeaderDropdownItem>
                        <br/>
                        <HeaderDropdownItem to="/services/burial/">
                            Immediate Burial
                        </HeaderDropdownItem>
                        <br/>
                        <HeaderDropdownItem to="/services/green-burial/">
                            Green Burial
                        </HeaderDropdownItem>
                        <br/>

                        <HeaderDropdownItem to="/services/funeral/">
                            Traditional Funeral
                        </HeaderDropdownItem>
                        <br/>
                        <HeaderDropdownItem to="/services/aquamation/">
                            Aquamation
                        </HeaderDropdownItem>
                        <br/>
                        <HeaderDropdownItem to="/services/glass-orb/">
                            Glass Orb
                        </HeaderDropdownItem>
                        <br/>
                        <HeaderDropdownItem to="/services/fingerprint-keepsake/">
                            Fingerprint Keepsakes
                        </HeaderDropdownItem>
                        <br/>
                        <HeaderDropdownHref href="https://plantingmemoriesnc.com/cityofoaks/">
                            Planting Memories
                        </HeaderDropdownHref>
                        <br/>
                        <HeaderDropdownHref href="https://cityofoakscremation.memorialstores.com">
                            Urn Store
                        </HeaderDropdownHref>
                        <br/>
                        <HeaderDropdownHref href="http://www.buymyownflowers.com/">
                            Flower Store
                        </HeaderDropdownHref>
                        <br/>
                        <HeaderDropdownHref href="https://www.parkermonumentsinc.com">
                            Monuments
                        </HeaderDropdownHref>
                        <br/>
                        {/* <HeaderDropdownHref href='https://www.funeralprints.com'>
              Prayer Cards, Etc.
            </HeaderDropdownHref>
            <br /> */}
                        <HeaderDropdownItem to="/espanol/">Español</HeaderDropdownItem>
                    </HeaderDropdown>
                    <HeaderLink disabled={true} to="/obituaries/featured/">
                        Obituaries
                    </HeaderLink>
                    <HeaderLink to="/contact/">Contact Us</HeaderLink>
                </div>
            </div>
        </div>
    );
};

export default Header;

import cx from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

const Footer = ({ className, ...props }) => {
  className = cx(
    'w-full px-8 py-4 text-white bg-gray-800 flex flex-col md:flex-row justify-between items-start md:items-center',
    className
  );
  return (
    <>
      <div className={className} {...props}>
        <div className='flex-initial text-xl font-semibold'>
          City of Oaks Funeral Home & Cremations
        </div>
        <div>
          <ul className='flex flex-col md:flex-row text-sm -mx-3 font-medium'>
            <li className='mx-3'>
              <Link to='/'>Home</Link>
            </li>
            <li className='mx-3'>
              <Link to='/contact/'>Contact</Link>
            </li>
            <li className='mx-3'>©2021 City of Oaks Cremation</li>
          </ul>
        </div>
      </div>

      <div className='w-full px-8 py-2 text-white text-xs font-light bg-black flex flex-col sm:flex-row justify-between items-start md:items-center'>
        <div>
          <strong className='font-bold'>Phone: </strong>
          <a href='tel:919-438-1649'>919-438-1649</a>
          <Link to='/contact/'>
            <strong className='ml-3 font-bold'>Email: </strong>
            info@cityofoakscremation.com
          </Link>
          <p>
            <a href='https://goo.gl/maps/pM8aWCPdmQn'>
              4900 Green Road, Raleigh, NC 27616
            </a>
          </p>
        </div>
        <div>
          <Link to='/' className='hover:underline'>
            www.cityofoakscremation.com
          </Link>
        </div>
      </div>
    </>
  );
};

export default Footer;

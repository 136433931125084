import React from 'react';

import './hero.css';

const Hero = ({ hasHero, size, image, head, subHead, text, children }) => {
  return (
    <div
      className='w-full bg-cover bg-center'
      style={{
        height: `${size || `32rem`}`,
        backgroundImage: `url(${image})`,
      }}
    >
      <div className='flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-50'>
        <div className='heroBgFloatBox'>
          <div className='text-center'>
            <h2
              className='heroHeadText text-white text-5xl font-black uppercase'
              // style={{ textDecoration: "underline overline" }}
              // style={{ borderBottom: "solid 2px", borderTop: "solid 2px" }}
            >
              {head}
            </h2>
            <h3 className='text-white uppercase text-2xl font-light'>
              {subHead}
            </h3>
            <h4 className='font-serif text-gray-200 pt-3 text-2xl'>{text}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

import React from 'react';

import banner from '../assets/images/banner.webp';
import './hero.css';

const Banner = ({ size, image, children }) => {
  return (
    <div
      className='w-full bg-cover bg-center'
      style={{
        height: `${size || `18rem`}`,
        backgroundImage: `${image ? `url(${image})` : banner}`,
      }}
    ></div>
  );
};

export default Banner;

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import '../css/style.css';
import Banner from './banner.js';
import Footer from './footer';
import Header from './header';
import Hero from './hero.js';

const Layout = ({
  className,
  headerClass,
  bodyClass,
  hasHero,
  heroSize,
  heroImage,
  heroHead,
  heroSubHead,
  heroText,
  children,
  ...props
}) => {
  bodyClass = bodyClass || 'container mx-auto px-6 md:px-10 lg:px-16 mb-12';
  return (
    <div className={className} {...props}>
      {/* <SEO /> */}
      <Helmet>
        <body className='font-sans antialiased' />
        <ClickCease />
        <FacebookSDK />
        <GoogleGtag />
      </Helmet>

      <Header className={headerClass} />
      {hasHero ? (
        <Hero
          hasHero={hasHero}
          size={heroSize}
          image={heroImage}
          head={heroHead}
          subHead={heroSubHead}
          text={heroText}
        />
      ) : (
        <Banner size={heroSize} image={heroImage} />
      )}
      <div className={bodyClass}>{children}</div>
      <Footer />
    </div>
  );
};

const GoogleGtag = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('event', 'conversion', {
      send_to: 'AW-799858601/EomyCMmDqMICEKm_s_0C',
    });
  }, []);
  return <></>;
};

const FacebookSDK = () => {
  useEffect(() => {
    window.fbAsyncInit = function () {
      FB.init({
        appId: 'your-app-id',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v11.0',
      });
    };
  }, []);
  return (
    <>
      <script
        async
        defer
        crossorigin='anonymous'
        src='https://connect.facebook.net/en_US/sdk.js'
      ></script>
    </>
  );
};

const ClickCease = () => {
  return (
    <>
      <script type='text/javascript'>
        var script = document.createElement('script'); script.async = true;
        script.type = 'text/javascript'; var target =
        'https://www.clickcease.com/monitor/stat.js'; script.src = target;var
        elem = document.head;elem.appendChild(script);
      </script>
      <noscript>
        <a href='https://www.clickcease.com' rel='nofollow'>
          <img
            src='https://monitor.clickcease.com/stats/stats.aspx'
            alt='ClickCease'
          />
        </a>
      </noscript>
    </>
  );
};

export default Layout;
